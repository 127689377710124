import SuccessIcon from '../icons/checkOutlined.svg'
import isProduction from '../utils/helpers/isProduction';

export const STRIPE_KEY = isProduction()
    ? 'pk_live_WNFteFfeZYrWAzQGceSSmDXD'
    : 'pk_test_Uy4a9tomQRukBXm14a5VfgME'

export const NUMBER_REGEXP = /^[0-9]+$/
export const editQuiz = {
    careReceipients: 'quizCareReceipients',
    legalRepresentations: 'quizLegalRepresentation',
    authorizedPerson: 'quizAuthorizedPerson',
    representativePerson:'quizRepresentativePerson',
    representationByCommerciallyActive:'commerciallyActive',
    representationByPrivateIndividual:'privateIndividual'
}


export const ToastIcons = {
    success: SuccessIcon,
    // error: ErrorIcon,
    // info: InfoIcon,
}

export const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
