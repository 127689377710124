import React, {useRef } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import Edit from "../../../../icons/edit.svg";
import TypingEffect from "../TypingEffect";
import {setLegalRepresentations} from "../../../../redux/actions/careReceipients";
import {setLegalRepresentation} from "../../../../redux/actions/quizQuestions";
import {useDispatch, useSelector} from "react-redux";
import {uploadFile} from "../../../../api/formApi";


const LegalChallenge = (
    {
        quizLegal,
        indexLegal,
        notLegalAnsweredIndex,
        editList,
        setEdit,
        getTextValue,
        handleLegalTextAnswer,
        edit,
        handleEdit,
        handleCancel,
        legalRepresentations,
        messageRefs
    }
) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const fileName = quizLegal.find((item)=>item.type === 'file')?.fileName
    const {
        payment:{reimbursementRef},
        quizQuestions:{editSummary}
    } = useSelector((state) => state)

    const handleFileChange = (e,index) => {
        const file = e.target.files[0]
        let formData = new FormData();
        formData.append('file', file);
        uploadFile(formData,reimbursementRef,'RC_LEGAL_REPRESENTATION')
            .then((res)=> {
                // console.log(res)
                // setAnswer(res.amazonUrl)
                const newQuiz= quizLegal.map((item,i)=> {
                    if(index === i){
                        return {
                            ...item,
                            answers: res.amazonUrl,
                            fileName: file.name
                        }
                    }
                    return item
                })
                dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[index],res.amazonUrl))
                dispatch(setLegalRepresentation(newQuiz,indexLegal))
            })
            .catch((res)=> console.log(res))
    };
    const handleDivClick = () => {
        // Simulate click on hidden input when div is clicked
        fileInputRef.current.click();
    };
    const downloadFile = (url, name) => {
        fetch(url)
            .then((response) => (response.blob()))
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${name}`,
                );
                document.body.appendChild(link);
                link.click();
                link?.parentNode?.removeChild(link);
            })
            .catch((err) => {
                console.log(err)
            })

    }
    return(
        <>
            {
                quizLegal.slice(0, notLegalAnsweredIndex + 1).map((item,i)=>{
                    return (
                        <>
                            <li className={styles.odd} ref={el => (messageRefs.current['legalRepresentations' + i] = el)}>
                                <img src={item?.img} alt={item?.img}/>
                                <p>
                                    {
                                        item.answers ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                    }
                                </p>
                            </li>
                            {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                                [styles.hideList]: editList !== 1,
                                [styles.borderLi] : !!item.edit
                            })}
                                                   onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                                {!!item.edit ? <>
                                            <textarea onChange={(e)=>getTextValue(e)} autoFocus={true} >
                                                {item?.answers}
                                            </textarea>
                                        <div>
                                            <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                            <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                handleLegalTextAnswer(i)
                                            }}/>
                                        </div>
                                    </>
                                    : <p>
                                        {item.type==='file'? item.fileName : item?.answers}
                                        {item?.type === 'file' && <img src={Download} alt={Download} onClick={()=>downloadFile(legalRepresentations.document,fileName)}/>}
                                        {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=> item.type === 'file'? handleDivClick()  : handleEdit(i)}/>}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e)=>handleFileChange(e,i)}
                                            accept="image/jpeg,image/png,.pdf"
                                        />
                                    </p>
                                }
                            </li> }
                        </>)
                })
            }
        </>
    )
}
export default LegalChallenge
