const USER_LOGOUT = 'USER_LOGOUT'
const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'
const SET_USER_EMAIL = 'SET_USER_EMAIL'
const SET_CHALLENGE_STEPS = 'SET_CHALLENGE_STEPS'
const SET_CARE_QUIZ_QUESTIONS = 'SET_CARE_QUIZ_QUESTIONS'
const SET_LEGAL_QUIZ_QUESTIONS = 'SET_LEGAL_QUIZ_QUESTIONS'
const SET_AUTHORIZED_QUIZ_QUESTIONS = 'SET_AUTHORIZED_QUIZ_QUESTIONS'
const SET_REPRESENTATIVE_QUIZ_QUESTIONS = 'SET_REPRESENTATIVE_QUIZ_QUESTIONS'
const SET_COMMERCIALLY_ACTIVE_QUIZ = 'SET_COMMERCIALLY_ACTIVE_QUIZ'
const SET_PRIVATE_INDIVIDUAL_QUIZ = 'SET_PRIVATE_INDIVIDUAL_QUIZ'
const SET_COMMERCIALLY_ACTIVE = 'SET_COMMERCIALLY_ACTIVE'
const SET_PRIVATE_INDIVIDUAL = 'SET_PRIVATE_INDIVIDUAL'
const SET_INDEX = 'SET_INDEX'
const SET_CARE_RECEIPIENTS = 'SET_CARE_RECEIPIENTS'
const SET_LEGAL_REPRESENTATION = 'SET_LEGAL_REPRESENTATION'
const SET_REIMBURSEMENTREF = 'SET_REIMBURSEMENTREF'
const SET_AUTHORIZED_PERSON = 'SET_AUTHORIZED_PERSON'
const SET_REPRESENTATIVE_PERSON ='SET_REPRESENTATIVE_PERSON'
const SET_QUESTION_GROUPS_STEP = 'SET_QUESTION_GROUPS_STEP'
const SET_CHOOSE_CARD = 'SET_CHOOSE_CARD'
const SET_EDIT_FROM_SUMMARY = 'SET_EDIT_FROM_SUMMARY'
const SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION = 'SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION'
const EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION = 'EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION'
const SET_UPDATE_CARE_RECEIPIENTS = 'SET_UPDATE_CARE_RECEIPIENTS'
const SET_UPDATE_LEGAL_REPRESENTATION = 'SET_UPDATE_LEGAL_REPRESENTATION'
const SET_UPDATE_AUTHORIZED_PERSON ='SET_UPDATE_AUTHORIZED_PERSON'
const SET_UPDATE_REPRESENTATIVE_PERSON = 'SET_UPDATE_REPRESENTATIVE_PERSON'
const SET_UPDATE_COMMERCIALLY_ACTIVE = 'SET_UPDATE_COMMERCIALLY_ACTIVE'
const SET_UPDATE_PRIVATE_INDIVIDUAL = 'SET_UPDATE_PRIVATE_INDIVIDUAL'
const SET_UPDATE_CARE_QUIZ = 'SET_UPDATE_CARE_QUIZ'

const SET_UPDATE_LEGAL_QUIZ = 'SET_UPDATE_LEGAL_QUIZ'
const SET_UPDATE_AUTH_QUIZ = 'SET_UPDATE_AUTH_QUIZ'
const SET_UPDATE_REP_QUIZ = 'SET_UPDATE_REP_QUIZ'
const SET_UPDATE_ACTIVE_QUIZ = 'SET_UPDATE_ACTIVE_QUIZ'
const SET_UPDATE_PRIVATE_QUIZ = 'SET_UPDATE_PRIVATE_QUIZ'
const SET_EMPTY_QUIZ = 'SET_EMPTY_QUIZ'
const SET_TOAST_NOTIFICATION = 'SET_TOAST_NOTIFICATION'
const CLOSE_TOAST_NOTIFICATION = 'CLOSE_TOAST_NOTIFICATION'
const SET_USER_DETAILS = 'SET_USER_DETAILS'
const SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL'
const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL'
const SET_EMPTY_CARE= 'SET_EMPTY_CARE'
const SET_EMPTY_LEGAL= 'SET_EMPTY_LEGAL'
const SET_EMPTY_AUTH= 'SET_EMPTY_AUTH'
const SET_EMPTY_REP= 'SET_EMPTY_REP'
const SET_EMPTY_ACTIVE= 'SET_EMPTY_ACTIVE'
const SET_EMPTY_PRIVATE= 'SET_EMPTY_PRIVATE'
const SET_EDIT_PARAMS= 'SET_EDIT_PARAMS'
const PHONE_NUMBER_FIELD_CHANGE= 'PHONE_NUMBER_FIELD_CHANGE'
const SET_FORM_EDIT= 'SET_FORM_EDIT'
export {
    USER_LOGOUT,
    SET_SELECTED_LANGUAGE,
    SET_USER_EMAIL,
    SET_CHALLENGE_STEPS,
    SET_CARE_QUIZ_QUESTIONS,
    SET_LEGAL_QUIZ_QUESTIONS,
    SET_AUTHORIZED_QUIZ_QUESTIONS,
    SET_REPRESENTATIVE_QUIZ_QUESTIONS,
    SET_COMMERCIALLY_ACTIVE_QUIZ,
    SET_PRIVATE_INDIVIDUAL_QUIZ,
    SET_INDEX,
    SET_CARE_RECEIPIENTS,
    SET_LEGAL_REPRESENTATION,
    SET_REIMBURSEMENTREF,
    SET_AUTHORIZED_PERSON,
    SET_REPRESENTATIVE_PERSON,
    SET_COMMERCIALLY_ACTIVE,
    SET_PRIVATE_INDIVIDUAL,
    SET_QUESTION_GROUPS_STEP,
    SET_CHOOSE_CARD,
    SET_EDIT_FROM_SUMMARY,
    SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
    EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
    SET_UPDATE_CARE_RECEIPIENTS,
    SET_UPDATE_LEGAL_REPRESENTATION,
    SET_UPDATE_AUTHORIZED_PERSON,
    SET_UPDATE_REPRESENTATIVE_PERSON,
    SET_UPDATE_COMMERCIALLY_ACTIVE,
    SET_UPDATE_PRIVATE_INDIVIDUAL,
    SET_UPDATE_CARE_QUIZ,
    SET_UPDATE_LEGAL_QUIZ,
    SET_UPDATE_AUTH_QUIZ,
    SET_UPDATE_REP_QUIZ,
    SET_UPDATE_ACTIVE_QUIZ,
    SET_UPDATE_PRIVATE_QUIZ,
    SET_EMPTY_QUIZ,
    SET_TOAST_NOTIFICATION,
    SET_USER_DETAILS,
    CLOSE_TOAST_NOTIFICATION,
    SET_CONFIRMATION_MODAL,
    CLOSE_CONFIRMATION_MODAL,
    SET_EMPTY_CARE,
    SET_EMPTY_REP,
    SET_EMPTY_AUTH,
    SET_EMPTY_LEGAL,
    SET_EMPTY_ACTIVE,
    SET_EMPTY_PRIVATE,
    SET_EDIT_PARAMS,
    PHONE_NUMBER_FIELD_CHANGE,
    SET_FORM_EDIT
}
