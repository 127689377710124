import React, {useEffect, useRef, useState} from "react";
import styles from "../../../Pages/Common/Footer/footer.module.scss";
import {
    insuranceCompanies, otherRelationshipList,
    relationshipByMarriageList,
    relationshipList
} from "../../../../constants/lists/relationshipList";
import {useDispatch, useSelector} from "react-redux";
import {setRepresentative} from "../../../../redux/actions/careReceipients";
import {setRepresentativePerson} from "../../../../redux/actions/quizQuestions";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import {useNavigate} from "react-router-dom";
import cx from "classnames";
import PhoneNumberField from "../../../ReusableField/PhoneNumberField";
import {handlePhoneNumberFieldChange} from "../../../../redux/actions/Auth";


const QuizRepresentative = () => {
    const { quizRepresentativePerson:{quizRep,indexRep} } = useSelector((state) => state.quizQuestions)
    const { representativePerson } = useSelector((state) => state)
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [answerRep, setAnswerRep] = useState('')
    const [repOption, setRepOption] = useState(false)
    const navigate = useNavigate()
    const wrapperRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setRepOption(false)
        }
    };
    useEffect(() => {

    }, [answerRep]);
    const handleChange = (e) => {
        setAnswerRep(e.currentTarget.value)
    }
    const handleRepAnswer = () => {
        // if( representativePerson.relationship && !location.state) {
        //     navigate('/chooseData')
        //     return;
        // }
        // location.state && ( representativePerson.relationship && location.state.from === '/chooseData') && navigate('/startChallenge')
        if(!answerRep) return
        const newQuiz= quizRep.map((item , i)=> {
            if( i === indexRep && item.question === 'Wie oder wo haben Sie die Vertretung gefunden?' && answerRep  && !location.state && !localStorage.getItem('accessToken')) {
                navigate('/chooseData')
            }
            if(i === indexRep){
                return {
                    ...item,
                    answers:  answerRep,
                    returnQuestion:(item.question === 'Pflegt die Vertretung neben dem Anstragsteller noch mindestens eine weitere Person ?' || item.question === 'Ist die Vertretung gewerblich als Pflegefachkraft tätig oder sogar eine Pflegefirma ?')
                    && (answerRep  === 'Nein' || answerRep === 'Nein') ? 'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.' :
                    item.question === 'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste. (Better : Ist die Vertretung mit (if possible: DEV to insert first name of caretaker) verwandt ? Bitte wählen Sie aus der Liste.)'
                    && answerRep !== 'nicht verwandt'?  'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.' :
                    item.question === 'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste. (Better : Ist die Vertretung mit (if possible: DEV to insert first name of caretaker) verschwägert ? Bitte wählen Sie aus der Liste.)'
                    && answerRep !== 'Nicht verschwägert' ? 'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.' :
                    item.question === 'Leben die Vertretung und der Antragsteller im selben Haushalt ? Bitte wählen ie aus der Liste.' && answerRep === 'Ja' ? 'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.' : ''
                }
            }
            return item
        })
        dispatch(setRepresentative(Object.keys(representativePerson)[indexRep],  answerRep))
        dispatch(setRepresentativePerson(newQuiz,indexRep + 1))
        dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))

        setAnswerRep('')
    }
    const clickRepOption = (e) => {
        if(!repOption){
            setRepOption(true)
        }else{
            setRepOption(false)
        }
    }
  const clickOptionOfRep = (e) => {
      setAnswerRep(e.target.innerText)
      setRepOption(false)
  }
    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    return (
       <>
           {quizRep?.map((item,i)=>{
               if(i === indexRep) {
                   switch (item?.type) {
                       case 'text':
                           return  <input type={'text'} pattern="" placeholder={'Type an answer'} value={answerRep}
                                          onChange={(e)=>handleChange(e)}
                                          onKeyDown={(e) => {
                                              if (e.key === 'Enter') handleRepAnswer()}} key={i} autoFocus={true}/>
                       case 'number':
                           return  <input type={'number'} pattern="" placeholder={'Type an answer'} value={answerRep}
                                          onChange={(e)=>handleChange(e)} onInput={(e) =>
                               e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                               if (e.key === 'Enter') handleRepAnswer()}} key={i} autoFocus={true}/>
                       case 'phoneNumber':
                           // return  <input type={'number'} pattern="" placeholder={'Type an answer'} value={answerRep}
                           //                onChange={(e)=>handleChange(e)}
                           //                onKeyDown={(e) => {
                           //                    if (e.key === 'Enter') handleRepAnswer()}} key={i} autoFocus={true}/>
                           return <PhoneNumberField
                               onChange={handleChangePhone}
                               phoneNumberObj={phoneNumberObj}
                               setAnswerr={setAnswerRep}
                           />
                       case 'option':
                           return <div className={styles.optionHid}>
                                   <div className={styles.select} onClick={clickRepOption} onKeyDown={(e) => {
                                       if (e.key === 'Enter') handleRepAnswer()}} key={i} ref={wrapperRef}>
                                       {!!answerRep ? answerRep : 'Select an option'}
                                   </div>
                                   {!!repOption && <div className={styles.option}>
                                       {item.question === 'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste. (Better : Ist die Vertretung mit (if possible: DEV to insert first name of caretaker) verwandt ? Bitte wählen Sie aus der Liste.)' ?
                                           relationshipList.map((item) => {
                                           return (
                                               <div onClick={clickOptionOfRep}>{item}</div>
                                           )
                                       }) : item.question === 'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste. (Better : Ist die Vertretung mit (if possible: DEV to insert first name of caretaker) verschwägert ? Bitte wählen Sie aus der Liste.)'?
                                           relationshipByMarriageList.map((item) => {
                                               return (
                                                   <div onClick={clickOptionOfRep}>{item}</div>
                                               )
                                           }) :  otherRelationshipList.map((item) => {
                                               return (
                                                   <div onClick={clickOptionOfRep}>{item}</div>
                                               )
                                           })
                                       }
                                   </div>}
                           </div>
                       case 'submit':
                           return (
                               <span>
                                    <input type={'submit'} placeholder={'Type an answer'} value={'Ja'}
                                               onClick={(e)=>handleChange(e)} key={i} className={styles.care} autoFocus={true}/>
                                    <input type={'submit'} placeholder={'Type an answer'} value={'Nein'}
                                              onClick={(e)=>handleChange(e)} key={i} className={styles.care}/>
                               </span>
                           )
                   }
               }
           })
           }
           {!representativePerson.relationship && <button disabled={!answerRep} className={cx({ [styles.btnDisabled]: !answerRep })} onClick={handleRepAnswer}>
               <img src={ArrowBtn} alt={ArrowBtn} />
           </button>}
       </>
    )
}
export default QuizRepresentative
