import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {setIndividual} from "../../../../redux/actions/careReceipients";
import {setPrivateIndividual} from "../../../../redux/actions/quizQuestions";
import CalendarIcon from "../../../../icons/calendar.svg";
import Calendar from "react-calendar";
import styles from "../../../Pages/Common/Footer/footer.module.scss";
import RightArrow from "../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../icons/leftArrowIcon.svg";
import Euro from "../../../../icons/Euro.svg";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import KM from '../../../../icons/km.svg'
import cx from "classnames";
const QuizPrivateIndividual = () => {
    const { privateIndividual:{quizPrivate,indexPrivate} } = useSelector((state) => state.quizQuestions)
    const { representationByPrivateIndividual } = useSelector((state) => state)
    const dispatch = useDispatch()
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const [answerPrivate, setAnswerPrivate] = useState('')
    const [showCalendar, setShowCalendar] = useState(false)
    const wrapper = useRef(null);

    // let sum = Number(representationByPrivateIndividual.respiteCare2Remuneration) + Number(representationByPrivateIndividual.respiteCare1Remuneration)
    // console.log('footer',sum);
    // useEffect(() => {
    //     if(indexPrivate === 8){
    //         const newQuiz= quizPrivate.map((item , i)=> {
    //             if(i === indexPrivate){
    //                 return {
    //                     ...item,
    //                     answers: isNaN(sum) ? '-' : sum
    //                 }
    //             }
    //             return item
    //         })
    //         dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[indexPrivate], sum))
    //         dispatch(setPrivateIndividual(newQuiz,indexPrivate + 1))
    //     }
    // }, [indexPrivate]);
    useEffect(() => {
        document.addEventListener("click", handleClickOut, false);
        return () => {
            document.removeEventListener("click", handleClickOut, false);
        };
    }, []);
    const handleClickOut = event => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setShowCalendar(false)
        }
    };
    const handleChange = (e) => {
        setAnswerPrivate(e.currentTarget.value)
    }
    const handlePrivateAnswer = () => {
        if(!answerPrivate) return
        const newQuiz= quizPrivate.map((item , i)=> {
            if(i === indexPrivate){
                return {
                    ...item,
                    answers: item.type === 'date' ? moment(answerPrivate).format('DD.MM.YYYY') : answerPrivate
                }
            }
            return item
        })
        dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[indexPrivate], answerPrivate))
        dispatch(setPrivateIndividual(newQuiz,indexPrivate + 1))
        setAnswerPrivate('')
    }

    const  handleChangePrivateCalendar = (e) =>{
        console.log(e);
        setAnswerPrivate(e)
        setShowCalendar(false)
    }
    const handleShowPrivateCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }

    return(
        <>
            {quizPrivate?.map((item,i)=>{
                if(i === indexPrivate) {
                    switch (item?.type) {
                        case 'text':
                            return  <input type={'text'} pattern="" placeholder={'Type an answer'} value={answerPrivate}
                                           onChange={(e)=>handleChange(e)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') handlePrivateAnswer()}} key={i} autoFocus={true}/>
                        case 'date':
                            return (<div>
                                <input placeholder="Type date" value={!answerPrivate ?
                                    moment(new Date(moment().year(), moment().month(), moment().date())).format('DD.MM.YYYY')
                                    : moment(answerPrivate).format('DD.MM.YYYY')} required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handlePrivateAnswer()}} key={i} autoFocus={true} onClick={handleShowPrivateCalendar} />
                                <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowPrivateCalendar}/>
                                {!!showCalendar && <Calendar
                                    onChange={(e) => handleChangePrivateCalendar(e)}
                                    value={!answerPrivate ? new Date(moment().year(),moment().month(),moment().date()) : answerPrivate}
                                    className={styles.reactCalendar}
                                    nextLabel={<img src={RightArrow} alt={'next'}/>}
                                    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
                                    next2Label={null}
                                    prev2Label={null}
                                    locale={moment.locale(selectedLanguage)}
                                />}
                            </div>)
                        case 'time' :
                            return (
                                <input id="appt-time" type="time" name="appt-time" required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handlePrivateAnswer()}} key={i}
                                       onChange={(e)=>handleChange(e)} autoFocus={true}/>
                            )
                        case 'number':
                            return  <input type={'number'} pattern="" placeholder={'Type an answer'} value={answerPrivate}
                                           onChange={(e)=>handleChange(e)} onInput={(e) =>
                                e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                                if (e.key === 'Enter') handlePrivateAnswer()}} key={i} autoFocus={true}/>
                        case 'integer':
                            return (
                                <div>
                                    <input type={'number'} pattern="" placeholder={'Type an answer'} value={answerPrivate}
                                           onChange={(e)=>handleChange(e)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') handlePrivateAnswer()}} key={i} autoFocus={true}/>
                                    <img src={
                                        item.question === 'Respite care 1: Remuneration' || item.question === 'Respite care 2: Remuneration' ?
                                        Euro : KM
                                    } alt={Euro} />
                                </div>
                            )
                        case 'submit':
                            return (
                                <span>
                                    {
                                        item.question === 'Sind Kosten für die Anreise angefallen ? Wählen sie aus der Liste' ?
                                        <>
                                            <input type={'submit'} placeholder={'Type an answer'} value={'Ja'}
                                                   onClick={(e)=>handleChange(e)} key={i} className={styles.care}/>
                                            <input type={'submit'} placeholder={'Type an answer'} value={'Nein'}
                                                   onClick={(e)=>handleChange(e)} key={i} className={styles.care} autoFocus={true}/>
                                        </> : <input type={'submit'} placeholder={'Type an answer'} value={'Ja'}
                                                     onClick={(e)=>handleChange(e)} key={i} className={styles.care}/>
                                    }

                                </span>
                            )


                    }
                }
            })
            }
            {!representationByPrivateIndividual.signatureOfThePerson && <button disabled={!answerPrivate} className={cx({ [styles.btnDisabled]: !answerPrivate})} onClick={handlePrivateAnswer}>
                <img src={ArrowBtn} alt={ArrowBtn} />
            </button>}
        </>
    )
}
export default QuizPrivateIndividual
