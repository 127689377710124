import React, {useEffect} from "react";
import styles from "../ChallengeSteps/challengeSteps.module.scss";
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import Edit from "../../../../icons/edit.svg";
import TypingEffect from "../TypingEffect";
import {useDispatch, useSelector} from "react-redux";
import {setConfirmationModal} from "../../../../redux/actions/confirmationModal";
import AuthModalComponent from "../NestedComponentOfModal/AuthModalComponent";
import RepModalComponent from "../NestedComponentOfModal/RepModalComponent";
// import TypingEffect from "../TypingEffect";

const RepresentativeChallenge = ({
    quizRep,
    notRepAnsweredIndex,
    editList,
    setEdit,
    getTextValue,
    handleRepTextAnswer,
    edit,
    handleEdit,
    handleCancel,
    messageRefs
}) => {
    const {
        quizQuestions:{editSummary,groupName,index}
    } = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        quizRep.map((item,i)=>{
            if(!!item.edit && index === i){
                console.log(item);
                (item.type === 'option' || item.type === 'submit') &&
                handleRepModal(item,i)
            }
        })
    }, [groupName]);
    const handleRepModal = (item,i) => {
        dispatch(
            setConfirmationModal({
                headerText: 'Edit',
                body: <RepModalComponent item={item} repIndex={i}/>,
                large:true
            })
        )
    }
    return (
        <>
            {
                quizRep.slice(0, notRepAnsweredIndex + 1).map((item,i)=>{
                    return (
                        <>
                            <li className={styles.odd} ref={el => (messageRefs.current['representativePerson' + i] = el)}>
                                <img src={item?.img} alt={item?.img}/>
                                <p>
                                    {
                                        item.answers ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                    }
                                </p>
                            </li>
                            {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                                [styles.hideList]: editList !== 1,
                                [styles.borderLi] : !!item.edit && (item.type==='text' || item.type==='number' || item.type==='phoneNumber')
                            })}
                                                   onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                                {!!item.edit  && (item.type==='text' || item.type==='number' || item.type==='phoneNumber') ? <>
                                            <textarea onChange={(e)=>getTextValue(e)} autoFocus={true}>
                                                {item?.answers}
                                            </textarea>
                                        <div>
                                            <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                            <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                handleRepTextAnswer(i)
                                            }}/>
                                        </div>
                                    </>
                                    : <p>
                                        {item.answers}
                                        {item?.type === 'file' && <img src={Download} alt={Download}/>}
                                        {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=> item.type === 'option' || item.type === 'submit'?
                                            handleRepModal(item,i)
                                            :handleEdit(i)}/>}
                                    </p>
                                }
                            </li> }
                            {item?.returnQuestion &&
                                <div className={styles.returnQuestion}>
                                    <img src={item?.img} alt={item?.img}/>
                                    <p>
                                        {item?.returnQuestion}
                                    </p>
                                </div>
                            }
                        </>)
                })
            }
        </>
    )
}
export default RepresentativeChallenge
