import React, {useEffect, useRef} from 'react'
import styles from './header.module.scss'
import Logo from '../../../../icons/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import ButtonDropDown from "../../../UiKitComponents/ButtonDropDown/ButtonDropDown";
import {setSelectedLanguage} from "../../../../redux/actions/metaData";
import { logOutUser, setUserDetails, setUserEmail } from '../../../../redux/actions/authActions';
import AccountDropDown from '../../../UiKitComponents/AccountDropDown'
// import {setHeaderRef} from "../../../../redux/actions/headerRef";
import { getUserData } from '../../../../api/userApi';
import logOut from '../../../../redux/thunks/account/logOut';

const Header = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const accessToken = localStorage.getItem('accessToken')
    const userData = useSelector((state) => state.userDetails)
    // const refHeader = useRef(null)
    // useEffect(() => {
    //     dispatch(setHeaderRef(refHeader.current.offsetHeight))
    // }, []);

    useEffect(() => {
        accessToken &&
        getUserData()
            .then((data) => {
                dispatch(setUserDetails(data))
            })
            .catch((err) => console.log(err))
    }, []);


    const handleChangeLanguage = (value) => {
        dispatch(setSelectedLanguage(value))
        // i18next.changeLanguage(value).then(() => dispatch(setSelectedLanguage(value)))
        localStorage.setItem('language', value)
    }

    const handleLogoClick = () => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/')
            dispatch(logOut())
        } else {
            navigate('/profile')
        }
    }

  return (
    <header className={styles.header}>
      <img src={Logo} alt={'FlexxiCare Logo'} onClick={handleLogoClick} />
        <div>
            <ButtonDropDown
                value={selectedLanguage.toUpperCase()}
                options={[
                    { label: 'English', value: 'en' },
                    { label: 'Deutsch', value: 'de' },
                ]}
                onChange={handleChangeLanguage}
            />
            <div>
                { accessToken && <AccountDropDown />}
                {(userData.option === 2 || userData.option === 3) &&
                    <div className={styles.userType}>{userData.option === 2 ? 'PRO' : userData.option === 3 ? 'PREMIUM': ''}</div>}
            </div>
        </div>
    </header>
  )
}

export default Header
