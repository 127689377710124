import { EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION, SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION } from '../../ActionTypes';

export const setSignInWithEmailFormValidation = (field, message) => ({
    type: SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
    field,
    message,
})

export const emptySignInWithEmailFormValidation = () => ({
    type: EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
})