import {apiGet} from "./api";

export const getUserData = () => {
    return apiGet(`api/v1/reimbursement-accounts/me`)
}
export const getReimbursements = (ref) =>{
    return apiGet(`api/v1/reimbursements/${ref}`)
}



