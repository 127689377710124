import React from 'react'
import styles from './accountPickList.module.scss'
// import AccountSignOutIcon from '../../../Icons/accountSignOut.svg'
import { useDispatch, useSelector } from 'react-redux'
import logOut from '../../../../redux/thunks/account/logOut';
import { closeConfirmationModal, setConfirmationModal } from '../../../../redux/actions/confirmationModal';
// import logOut from '../../../../redux/thunks/account/logOut'
// import {
//     closeConfirmationModal,
//     setConfirmationModal,
// } from '../../../../redux/actions/confirmationModal'

const AccountPickList = () => {
    const dispatch = useDispatch()
    // const selectedKey = useSelector((state) => state.account).navKey

    // const handleNavItemClick = (key) => {
    //     if (key === selectedKey) {
    //         return
    //     }
    //     dispatch(changeAccountLeftNavigation(key))
    // }
    return (
        <ul className={styles.pickListWrapper}>
            {/*{leftNavigation.map(({ label, src, key }) => {*/}
            {/*    return (*/}
            {/*        <li onClick={() => handleNavItemClick(key)} key={key}>*/}
            {/*            <img src={src} alt={label} />*/}
            {/*            <Link to="/account">{t(`${label}`)}</Link>*/}
            {/*        </li>*/}
            {/*    )*/}
            {/*})}*/}
            {
                <li
                    onClick={() => {
                        dispatch(
                            setConfirmationModal({
                                headerText: 'Log out',
                                body: 'Are you sure you want to log out',
                                confirm: {
                                    text: 'Log out',
                                },
                                cancel: {
                                    text: 'Cancel',
                                },
                                large: true,
                                handleCancel: () => {
                                    dispatch(closeConfirmationModal())
                                },
                                handleConfirm: () => {
                                    dispatch(logOut())
                                    dispatch(closeConfirmationModal())
                                },
                            })
                        )
                    }}
                >
                    {/*// <img src={AccountSignOutIcon} alt={'Log out'} />*/}
                    <a> {'Log out'}</a>
                </li>
            }
        </ul>
    )
}

export default AccountPickList
