import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CalendarIcon from "../../../../icons/calendar.svg";
import Calendar from "react-calendar";
import moment from "moment";
import styles from "../../../Pages/Common/Footer/footer.module.scss";
import RightArrow from "../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../icons/leftArrowIcon.svg";
import {reasonsOfAbsence} from "../../../../constants/lists/relationshipList";
import {setAuthorizedPerson} from "../../../../redux/actions/careReceipients";
import {setAuthPerson} from "../../../../redux/actions/quizQuestions";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import cx from "classnames";
import {handlePhoneNumberFieldChange} from "../../../../redux/actions/Auth";
import PhoneNumberField from "../../../ReusableField/PhoneNumberField";

const AuthorizePerson = () => {
    const [answerAuth, setAnswerAuth] = useState('')
    const { quizAuthorizedPerson:{ quizAuth,indexAuth} } = useSelector((state) => state.quizQuestions)
    const { authorizedPerson, legalRepresentations , careReceipients} = useSelector((state) => state)
    const [showCalendar, setShowCalendar] = useState(false)
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const [option, setOption] = useState(false)
    const dispatch = useDispatch()
    const wrapperRef = useRef(null);
    const wrapper = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    useEffect(() => {
        document.addEventListener("click", handleClickOut, false);
        return () => {
            document.removeEventListener("click", handleClickOut, false);
        };
    }, []);
    const handleClickOut = event => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setShowCalendar(false)
        }
    };
    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOption(false)
        }
    };
    useEffect(() => {

    }, [careReceipients,legalRepresentations, authorizedPerson]);
    useEffect(() => {

    }, [authorizedPerson.professionalCarer]);
    const handleChange = (e) => {
      setAnswerAuth(e.currentTarget.value)
    }
    const handleAuthAnswer = () => {
        if(!answerAuth) return
        const newQuiz= quizAuth.map((item , i)=> {
            if(i === indexAuth){
                return {
                    ...item,
                    answers: item.type === 'date' || item.type === 'birthDay' ? moment(answerAuth).format('DD.MM.YYYY') : answerAuth,
                    returnQuestion: answerAuth === 'Ja'  || answerAuth === 'Unter 6 monate' ? 'Reimbursement of costs for respite care is not possible for commercial carers and care companies' :'',
                }

            }
            return item
        })
        dispatch(setAuthorizedPerson(Object.keys(authorizedPerson)[indexAuth],answerAuth))
        dispatch(setAuthPerson(newQuiz,indexAuth + 1))
        dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))
        setAnswerAuth('')
    }
    const authOption = () => {
        if(!option){
            setOption(true)
        }else{
            setOption(false)
        }
    }
    const clickAuthOption = (e) => {
        setAnswerAuth(e.target.innerText)
        setOption(false)
    }
    const  handleChangeAuthCalendar = (e) =>{
        setAnswerAuth(e)
        setShowCalendar(false)
    }
    const handleShowAuthCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }
    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    return(
        <>
            {quizAuth?.map((item,i)=>{
                if(i === indexAuth) {
                    switch (item?.type) {
                        case 'text':
                            return  <input type={'text'} pattern="" placeholder={'Type an answer'} value={answerAuth}
                                           onChange={(e)=>handleChange(e)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') handleAuthAnswer()}} autoFocus={true}/>
                        case 'number':
                            return  <input type={'number'} pattern="" placeholder={'Type an answer'} value={answerAuth}
                                           onChange={(e)=>handleChange(e)} onInput={(e) =>
                                e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                                if (e.key === 'Enter') handleAuthAnswer()}} autoFocus={true}/>
                        case 'phoneNumber':
                            return <PhoneNumberField
                                onChange={handleChangePhone}
                                phoneNumberObj={phoneNumberObj}
                                setAnswerr={setAnswerAuth}
                            />
                        case 'date':
                            return (<div>
                                <input placeholder="Type date" value={!answerAuth ?
                                    moment(new Date(moment().year(), moment().month(), moment().date())).format('DD.MM.YYYY')
                                    : moment(answerAuth).format('DD.MM.YYYY')} required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handleAuthAnswer()}} autoFocus={true} onClick={handleShowAuthCalendar} />
                                <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowAuthCalendar}/>
                                {!!showCalendar && <Calendar
                                    onChange={(e) => handleChangeAuthCalendar(e)}
                                    value={!answerAuth ? new Date(moment().year(),moment().month(),moment().date()) : answerAuth}
                                    className={styles.reactCalendar}
                                    // minDate={item.question === 'Period of absence to\n' && authorizedPerson?.periodOfAbsenceFrom}
                                    // maxDate={moment(authorizedPerson?.periodOfAbsenceFrom)}
                                    nextLabel={<img src={RightArrow} alt={'next'}/>}
                                    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
                                    next2Label={null}
                                    prev2Label={null}
                                    locale={moment.locale(selectedLanguage)}
                                />}
                            </div>)
                        case 'birthDay':
                            return (<div>
                                <input placeholder={moment().format('DD.MM.YYYY')} value={!answerAuth ?
                                    moment(new Date(moment().year(),moment().month(),moment().date())).format('DD.MM.YYYY')
                                    : moment(answerAuth).format('DD.MM.YYYY')} required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handleAuthAnswer()}} autoFocus={true} onClick={handleShowAuthCalendar} />
                                <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowAuthCalendar}/>
                                {!!showCalendar && <Calendar
                                    onChange={(e) => handleChangeAuthCalendar(e)}
                                    value={!answerAuth ? new Date(moment().year(),moment().month(),moment().date()) : answerAuth}
                                    className={styles.reactCalendar}
                                    nextLabel={<img src={RightArrow} alt={'next'}/>}
                                    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
                                    next2Label={null}
                                    prev2Label={null}
                                    locale={moment.locale(selectedLanguage)}
                                />}
                            </div>)
                        case 'option':
                            return  <div className={styles.optionHid}>
                                <div className={styles.select} onClick={authOption} onKeyDown={(e) => {

                                    if (e.key === 'Enter') handleAuthAnswer()}} ref={wrapperRef}>
                                    {!!answerAuth ? answerAuth : 'Select an option'}
                                </div>
                                {!!option && <div className={styles.option}>
                                    {reasonsOfAbsence.map((item) => {
                                        return (
                                            <div onClick={clickAuthOption}>{item}</div>
                                        )
                                    })}
                                </div>}
                            </div>
                        case 'submit':
                            return (<span>
                                    <input type={'submit'} placeholder={'Type an answer'}
                                           value={item.question === 'Ist die verhinderte Pflegeperson gewerblich als Pfleger tätig oder sogar eine Pflegefirma ?' ?
                                    'Ja': item.question === 'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?' ? 'Unter 6 monate' : 'unter 8 Stunden täglich'}
                                           onClick={(e)=>handleChange(e)} className={styles.care} />
                                   <input type={'submit'} placeholder={'Type an answer'}
                                          value={item.question === 'Ist die verhinderte Pflegeperson gewerblich als Pfleger tätig oder sogar eine Pflegefirma ?' ?
                                              'Nein': item.question === 'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?' ? 'über 6 Monate' : 'über 8 Stunden täglich'}
                                          onClick={(e)=>handleChange(e)} className={styles.care} autoFocus={true}/>
                                    </span>)
                    }
                }
            })
            }
            {!authorizedPerson.durationOfAbsence && <button disabled={!answerAuth} className={cx({ [styles.btnDisabled]: !answerAuth })} onClick={handleAuthAnswer}>
                <img src={ArrowBtn} alt={ArrowBtn} />
            </button>}
        </>
    )
}
export default AuthorizePerson
