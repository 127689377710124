import React, {useEffect, useState} from "react";
import styles from './startChallenge.module.scss'
import Robbot from '../../../icons/robbot.svg'
import Check from '../../../icons/check.svg'
import ArrowRight from '../../../icons/arrowRight.svg'
import Avatar from '../../../icons/avatar.svg'
import { setUserDetails, setUserEmail } from '../../../redux/actions/authActions';
import {useDispatch, useSelector} from "react-redux";
import { startAccountRobot, login } from '../../../api/loginApi';
import emailValidator from "../../../constants/validators/emailValidator";
import PageComponent from '../../Pages/PageComponent';
import {useNavigate} from "react-router-dom";
import {
    emptySignInWithEmailFormValidation,
    setSignInWithEmailFormValidation
} from '../../../redux/actions/validation';
import Input from '../../UiKitComponents/Input';
import { EIMBURSEMENT_ACCOUNT_LOGIN_REQUIRED, REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS, REIMBURSEMENT_ACCOUNT_NOT_FOUND } from '../../../constants/responseStatuses';
import {setReimbursementRef} from "../../../redux/actions/payment";
import { getUserData } from '../../../api/userApi';
import { createAuthUserForm } from '../../../api/formApi';
const StartChallenge = () => {
    const dispatch = useDispatch()
    const { email} = useSelector((state)=>state.userDetails)
    const {
        validation: {
            signInWithEmailFormValidation: { emailVal, passwordVal },
        },
    } = useSelector((state) => state)
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loginByEmailPassword, setLoginByEmailPassword] = useState(false)
    const navigate = useNavigate()

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleStart()
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [email, password, emailVal, passwordVal])
    useEffect(() => {
        return () => {
            dispatch(emptySignInWithEmailFormValidation())
        }
    }, [])

    const handleStart = () => {
        if(!loginByEmailPassword && validationEmail('email', email)){
            return
        }
        if(loginByEmailPassword && (validationEmail('email', email) || validationPassword('password', password))){
            return
        }
        if(!loginByEmailPassword && !validationEmail('email', email)){
            const requestBody = {
                email,
                params : {}
            }
            startAccountRobot(requestBody)
                .then((data)=> {
                    dispatch(setReimbursementRef(data.ref))
                    if(data.email){
                        navigate('/startChallenge')
                    }
                })
                .catch((err) => {
                    if (err.errorCode === EIMBURSEMENT_ACCOUNT_LOGIN_REQUIRED) {
                        setLoginByEmailPassword(true)
                    }
                })
        }
        if(loginByEmailPassword && !validationEmail('email', email) && !validationPassword('password', password)){
            const body = {
                email,
                password
            }
            login(body)
                .then((data) => {
                    localStorage.setItem('accessToken', data.accessToken)
                    localStorage.setItem('refreshToken', data.refreshToken)
                    // const requestBody = {
                    //     email,
                    //     params : {}
                    // }
                    // createAuthUserForm(requestBody)
                    //     .then((data)=>{
                    //         dispatch(setReimbursementRef(data.ref))
                    //     })
                    //     .catch((err) => console.log(err))
                    // getUserData()
                    //     .then((data) => {
                    //         dispatch(setUserDetails(data))
                    //     })
                    //     .catch((err) => console.log(err))
                    navigate('/profile')
                })
                .catch((err) => {
                    if (err.errorCode === REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS) {
                        setErrorMessage('Wrong credentials' )
                        return
                    }
                    if (err.errorCode === REIMBURSEMENT_ACCOUNT_NOT_FOUND) {
                        setErrorMessage('Reimbursement account not found')
                        return
                    }
                })
        }

    }
    const validationEmail = (field, value) => {
        let inValid = false
        if (!value) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Email is required'))
        } else if (!emailValidator(value)) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Email is invalid'))
        }
        return inValid
    }
    const handleChangeEmail = (field, value) =>{
        dispatch(setSignInWithEmailFormValidation(field + 'Val', null))
        dispatch(setUserEmail(value))
    }
    const handleBlurEmailField = (field, value) => {
        setErrorMessage('')
        validationEmail(field, value)
    }
    const validationPassword = (field, value) => {
        let inValid = false
        if (!value) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Password is required'))
        } else if (value.trim().length < 8) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Password is invalid'))
        }
        return inValid
    }
    const handleBlurPasswordField = (field, value) => {
        setErrorMessage('')
        validationPassword(field, value)
    }
    const handleChangePassword = (field, value) =>{
        dispatch(setSignInWithEmailFormValidation(field + 'Val', null))
        setPassword(value)
    }

    return (
        <PageComponent>
            <div className={styles.startChallenge}>
                <div>
                    <div className={styles.challenge}>
                        <p>Challenge us together.<br/> Get estimate.</p>
                        <p>One sentence description should be here about .
                            One sentence description should be here about One sentence description should be here about </p>
                    </div>
                    <div className={styles.form}>
                        <Input
                            type='email'
                            placeholder={'E-Mail'}
                            value={email}
                            onChange={(e)=> handleChangeEmail('email', e.currentTarget.value)}
                            onBlur={(e) => handleBlurEmailField('email', e.currentTarget.value)}
                            validationMessage={emailVal}
                            maxLength="250"
                            required={true}
                        />
                        {loginByEmailPassword && <Input
                            type='password'
                            placeholder={'Passwort'}
                            value={password}
                            onChange={(e) => handleChangePassword('password', e.currentTarget.value)}
                            onBlur={(e) => handleBlurPasswordField('password', e.currentTarget.value)}
                            validationMessage={passwordVal}
                            showEye={true}
                            required={true}
                            autoFocus={true}
                        />}
                        {errorMessage && <span>{errorMessage}</span>}
                        <button onClick={handleStart}>Start <img src={ArrowRight} alt={ArrowRight}/></button>
                    </div>
                    <div className={styles.check}>
                        <div>
                            <img src={Check} alt={Check}/>
                            <p>Your personal data</p>
                        </div>
                        <div>
                            <img src={Check} alt={Check}/>
                            <p>Determine the skills</p>
                        </div>
                        <div>
                            <img src={Check} alt={Check}/>
                            <p>Other point will be written here </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.robbot}>
                        <img src={Robbot} alt={Robbot}/>
                    </div>
                    <div className={styles.contactPerson}>
                        <p>You can also contact us</p>
                        <div>
                            <div>
                                <img src={Avatar} alt={Avatar}/>
                            </div>
                            <div>
                                <p>Contact Person</p>
                                <p>Contact person as title</p>
                                <p>support@flexxi.care</p>
                                <p>+49 1234 56789</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageComponent>
 )
}

export default StartChallenge
