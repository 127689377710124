import React, {useState} from "react";
import styles from "../modalComponent.module.scss";
import FlexiiLogo from "../../../../../icons/flexiiLogo.svg";
import Calendar from "react-calendar";
import moment from "moment/moment";
import RightArrow from "../../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../../icons/leftArrowIcon.svg";
import Button from "../../../../UiKitComponents/Button";
import {closeConfirmationModal} from "../../../../../redux/actions/confirmationModal";
import {setAuthorizedPerson, setCommercially} from "../../../../../redux/actions/careReceipients";
import {setAuthPerson, setCommerciallyActive, setEditFromSummary} from "../../../../../redux/actions/quizQuestions";
import {handlePhoneNumberFieldChange} from "../../../../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import {insuranceCompanies, reasonsOfAbsence} from "../../../../../constants/lists/relationshipList";


const AuthModalComponent = ({ item ,activeIndex}) => {
    const { commerciallyActive:{quizActive,indexActive} } = useSelector((state) => state.quizQuestions)
    const { representationByCommerciallyActive } = useSelector((state) => state)
    const dispatch = useDispatch()
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const [answerActive, setAnswerActive] = useState('')
    let sum = Number(representationByCommerciallyActive.respiteCare2Remuneration) + Number(representationByCommerciallyActive.respiteCare1Remuneration)
    const  handleChangeActiveCalendar = (e) =>{
        setAnswerActive(e)
    }

    const handleActiveAnswer = (index) => {
        if(!answerActive) return
        const newQuiz= quizActive.map((item , i)=> {
            if(i === index){
                return {
                    ...item,
                    answers: item.answers === 'sum' ? sum :  item.type === 'date' ? moment(answerActive).format('DD.MM.YYYY') : answerActive
                }
            }

            return item
        })
        dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[index], answerActive))
        dispatch(setCommerciallyActive(newQuiz,indexActive ))
        setAnswerActive('')
    }
    return <section className={styles.modalComponent}>
        <p className={styles.odd}>
            <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
            <p>
                {item.question}
            </p>
        </p>
        {(item.type === 'date' && item.question === "Respite care 1: Date")&& <Calendar
            onChange={(e) => handleChangeActiveCalendar(e)}
            value={representationByCommerciallyActive.respiteCare1Date === '-' && !answerActive ?
                new Date(moment().year(),moment().month(),moment().date()) :
                representationByCommerciallyActive.respiteCare1Date !== '-' && !answerActive ?
                    new Date(representationByCommerciallyActive.respiteCare1Date):
                    answerActive}
            className={styles.reactCalendar}
            nextLabel={<img src={RightArrow} alt={'next'}/>}
            prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
            next2Label={null}
            prev2Label={null}
            locale={moment.locale(selectedLanguage)}
        />}
        {/*{(item.type === 'date' && item.question === "Respite care 2: Date")&& <Calendar*/}
        {/*    onChange={(e) => handleChangeActiveCalendar(e)}*/}
        {/*    value={representationByCommerciallyActive.respiteCare2Date === '-' && !answerActive ?*/}
        {/*        new Date(moment().year(),moment().month(),moment().date()) :*/}
        {/*        representationByCommerciallyActive.respiteCare2Date !== '-' && !answerActive ?*/}
        {/*            new Date(representationByCommerciallyActive.respiteCare2Date):*/}
        {/*            answerActive}*/}
        {/*    className={styles.reactCalendar}*/}
        {/*    nextLabel={<img src={RightArrow} alt={'next'}/>}*/}
        {/*    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}*/}
        {/*    next2Label={null}*/}
        {/*    prev2Label={null}*/}
        {/*    locale={moment.locale(selectedLanguage)}*/}
        {/*/>}*/}

        <div>
            <Button  small primaryOutlined onClick={() => {
                dispatch(closeConfirmationModal())
                dispatch(setEditFromSummary(''))
            } }>
                {'Abbrechen'}
            </Button>
            <Button
                primary
                small
                onClick={() => {
                    handleActiveAnswer(activeIndex)
                    dispatch(closeConfirmationModal())
                    dispatch(setEditFromSummary(''))
                }}
            >
                {'Speichern'}
            </Button>
        </div>
    </section>
}
export default AuthModalComponent
