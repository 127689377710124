import React, {useState} from "react";
import styles from "../modalComponent.module.scss";
import FlexiiLogo from "../../../../../icons/flexiiLogo.svg";
import Calendar from "react-calendar";
import moment from "moment/moment";
import RightArrow from "../../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../../icons/leftArrowIcon.svg";
import Button from "../../../../UiKitComponents/Button";
import {closeConfirmationModal} from "../../../../../redux/actions/confirmationModal";
import {setAuthorizedPerson, setCommercially, setIndividual} from "../../../../../redux/actions/careReceipients";
import {
    setAuthPerson,
    setCommerciallyActive,
    setEditFromSummary,
    setPrivateIndividual
} from "../../../../../redux/actions/quizQuestions";
import {handlePhoneNumberFieldChange} from "../../../../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import {insuranceCompanies, reasonsOfAbsence} from "../../../../../constants/lists/relationshipList";
import {representationByPrivateIndividual} from "../../../../../redux/defaultStates";


const AuthModalComponent = ({ item ,individualIndex}) => {
    const { privateIndividual:{quizPrivate,indexPrivate} } = useSelector((state) => state.quizQuestions)
    const { representationByPrivateIndividual } = useSelector((state) => state)
    const dispatch = useDispatch()
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const [answerPrivate, setAnswerPrivate] = useState('')
    const  handleChangePrivateCalendar = (e) =>{
        setAnswerPrivate(e)
    }
    const handleChange = (e) => {
        setAnswerPrivate(e.currentTarget.value)
    }
    const handlePrivateAnswer = (index) => {
        if(!answerPrivate) return
        const newQuiz= quizPrivate.map((item , i)=> {
            if(i === index){
                return {
                    ...item,
                    answers: item.type === 'date' ? moment(answerPrivate).format('DD.MM.YYYY') : answerPrivate
                }
            }
            return item
        })
        dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[index], answerPrivate))
        dispatch(setPrivateIndividual(newQuiz,indexPrivate))
        setAnswerPrivate('')
    }
    return <section className={styles.modalComponent}>
        <p className={styles.odd}>
            <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
            <p>
                {item.question}
            </p>
        </p>
        {(item.type === 'date'  && item.question === "Date")&& <Calendar
            onChange={(e) => handleChangePrivateCalendar(e)}
            // value={!answerPrivate ? new Date(representationByPrivateIndividual.date) : answerPrivate}
            value={representationByPrivateIndividual.date === '-' && !answerPrivate ?
                new Date(moment().year(),moment().month(),moment().date()) :
                representationByPrivateIndividual.date !== '-' && !answerPrivate ?
                    new Date(representationByPrivateIndividual.date):
                    answerPrivate }
            className={styles.reactCalendar}
            nextLabel={<img src={RightArrow} alt={'next'}/>}
            prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
            next2Label={null}
            prev2Label={null}
            locale={moment.locale(selectedLanguage)}
        />}
        {(item.type === 'date' && item.question === "Respite care 1: Date")&& <Calendar
            onChange={(e) => handleChangePrivateCalendar(e)}
            value={representationByPrivateIndividual.respiteCare1Date === '-' && !answerPrivate ?
                new Date(moment().year(),moment().month(),moment().date()) :
                representationByPrivateIndividual.respiteCare1Date !== '-' && !answerPrivate ?
                    new Date(representationByPrivateIndividual.respiteCare1Date):
                    answerPrivate }
            className={styles.reactCalendar}
            nextLabel={<img src={RightArrow} alt={'next'}/>}
            prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
            next2Label={null}
            prev2Label={null}
            locale={moment.locale(selectedLanguage)}
        />}
        {/*{(item.type === 'date' && item.question === "Respite care 2: Date")&& <Calendar*/}
        {/*    onChange={(e) => handleChangePrivateCalendar(e)}*/}
        {/*    value={representationByPrivateIndividual.respiteCare2Date === '-' && !answerPrivate ?*/}
        {/*        new Date(moment().year(),moment().month(),moment().date()) :*/}
        {/*        representationByPrivateIndividual.respiteCare2Date !== '-' && !answerPrivate ?*/}
        {/*            new Date(representationByPrivateIndividual.respiteCare2Date):*/}
        {/*            answerPrivate }*/}
        {/*    className={styles.reactCalendar}*/}
        {/*    nextLabel={<img src={RightArrow} alt={'next'}/>}*/}
        {/*    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}*/}
        {/*    next2Label={null}*/}
        {/*    prev2Label={null}*/}
        {/*    locale={moment.locale(selectedLanguage)}*/}
        {/*/>}*/}
        {item.type === 'submit' && (
            <span>
                {
                item.question === 'Sind Kosten für die Anreise angefallen ? Wählen sie aus der Liste' ?
                <>
                <input type={'submit'} placeholder={'Type an answer'} value={'Ja'}
                       onClick={(e)=>handleChange(e)} className={styles.care}
                       autoFocus={representationByPrivateIndividual.representingPersonByTransport === 'Ja'}/>
                <input type={'submit'} placeholder={'Type an answer'} value={'Nein'}
                       onClick={(e)=>handleChange(e)} className={styles.care}
                       autoFocus={representationByPrivateIndividual.representingPersonByTransport === 'Nein'}/>
                </> : <input type={'submit'} placeholder={'Type an answer'} value={'Ja'}
                         onClick={(e)=>handleChange(e)} className={styles.care}/>
                }

            </span>
            )
        }

        <div>
            <Button  small primaryOutlined onClick={() => {
                dispatch(closeConfirmationModal())
                dispatch(setEditFromSummary(''))
            } }>
                {'Abbrechen'}
            </Button>
            <Button
                primary
                small
                onClick={() => {
                    handlePrivateAnswer(individualIndex)
                    dispatch(closeConfirmationModal())
                    dispatch(setEditFromSummary(''))
                }}
            >
                {'Speichern'}
            </Button>
        </div>
    </section>
}
export default AuthModalComponent
