import React, {useEffect} from "react";
import styles from "../ChallengeSteps/challengeSteps.module.scss";
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import Edit from "../../../../icons/edit.svg";
import TypingEffect from "../TypingEffect";
import {useDispatch, useSelector} from "react-redux";
import {setConfirmationModal} from "../../../../redux/actions/confirmationModal";
import CareModalComponent from "../NestedComponentOfModal/CareModalComponent/CareModalComponent";
import ActiveModalComponent from "../NestedComponentOfModal/ActiveModalComponent";
import Euro from "../../../../icons/Euro.svg";
// import TypingEffect from "../TypingEffect";

const CommerciallyActiveChallenge = ({
    sumActive,
    quizActive,
    notActiveAnsweredIndex,
    editList,
    setEdit,
    getTextValue,
    handleActiveTextAnswer,
    edit,
    handleEdit,
    handleCancel,
    messageRefs
}) => {
    const {quizQuestions:{editSummary,groupName,index}} = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        quizActive.map((item,i)=>{
            if(!!item.edit && index === i){
                console.log(item);
                item.type === 'date'  && handleActiveModal(item,i)
            }
        })
    }, [groupName]);
    const handleActiveModal = (item,i) => {
        dispatch(
            setConfirmationModal({
                headerText: 'Edit',
                body: <ActiveModalComponent item={item} activeIndex={i}/>,
                large:true
            })
        )
    }
    return (
        <>
            {
                quizActive.slice(0, notActiveAnsweredIndex + 1).map((item,i)=>{

                    return (
                        <>
                            <li className={styles.odd}  ref={el => (messageRefs.current['representationByCommerciallyActive' + i] = el)}>
                                <img src={item?.img} alt={item?.img}/>
                                <p>
                                    {
                                        item.answers ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                    }
                                </p>
                            </li>
                            {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                                [styles.hideList]: editList !== 1,
                                [styles.borderLi] : !!item.edit && (item.type==='time' || item.type==='number' || item.type==='integer')
                            })} onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                                {!!item.edit && (item.type==='time' || item.type==='number' || item.type==='integer') ? <>
                                            <textarea onChange={(e)=>getTextValue(e)} autoFocus={true}>
                                                {item?.answers}
                                            </textarea>
                                        <div>
                                            <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                            <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                handleActiveTextAnswer(i)
                                            }}/>
                                        </div>
                                    </>
                                    : <p>
                                        {/*{item.question === 'Total amount' ? sumActive : item.answers}*/}
                                        {item.answers}
                                        {(item.question === 'Respite care 1: Remuneration' || item.question === 'Respite care 2: Remuneration' || item.type === 'sum')
                                            && <img src={Euro} alt={Euro} className={styles.euro}/>}
                                        {item?.type === 'file' && <img src={Download} alt={Download}/>}
                                        {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=>
                                            item.type === 'date' ? handleActiveModal(item,i) :handleEdit(i)}/>}
                                    </p>
                                }
                            </li> }
                        </>)
                })
            }
        </>
    )
}
export default CommerciallyActiveChallenge
