import React, {useState, useEffect} from "react";
import styles from './setPassword.module.scss'
import Robbot from '../../../../icons/robbot.svg'
import PageComponent from "../../../Pages/PageComponent";
import cx from 'classnames'
import {creatAccount} from "../../../../api/loginApi";
import { useNavigate } from "react-router-dom";
import Input from "../../../UiKitComponents/Input";
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../api/userApi';
import { setUserDetails } from '../../../../redux/actions/authActions';
import {
    emptySignInWithEmailFormValidation,
    setSignInWithEmailFormValidation
} from '../../../../redux/actions/validation';

const SetPassword = () =>{
    const email = useSelector((state) => state.userDetails).email
    const ref = useSelector((state) => state.payment).reimbursementRef
    const [password, setPassword] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        validation: {
            signInWithEmailFormValidation: { passwordVal },
        },
    } = useSelector((state) => state)
    useEffect(() => {
        return () => {
            dispatch(emptySignInWithEmailFormValidation())
        }
    }, [])

const handleSetPassword = () => {
    if(validationPassword('password', password)){
        return
    }
    let requestBody = {
        reimbursementRef: ref,
        password: password
    }
    creatAccount(requestBody)
        .then((data)=> {
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('refreshToken', data.refreshToken)
            getUserData()
                .then((data) => {
                    dispatch(setUserDetails(data))
                })
                .catch((err) => console.log(err))
            navigate('/summary')
         })
        .catch(() => {
           setErrorMessage('Whoops, something went wrong. Please contact our support team')
            return
        })
}
    const validationPassword = (field, value) => {
        let inValid = false
        if (!value) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Password is required'))
        } else if (value.trim().length < 8) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Val', 'Password is required'))
        }
        return inValid
    }
    const handleBlurPasswordField = (field, value) => {
        setErrorMessage('')
        validationPassword(field, value)
    }
    const handleChangePassword = (field, value) =>{
        dispatch(setSignInWithEmailFormValidation(field + 'Val', null))
        setPassword(value)
    }


    return(
        <PageComponent>
            <div className={styles.setPassword}>
                <div>
                    <h1>Now you should set a password</h1>
                    <p>
                        One sentence description should be here about .
                        One sentence description should be here about One sentence description should be here about
                    </p>
                    <div className={cx(styles.form,{
                        [styles.disable]:email
                    })}>
                        <Input
                            placeholder={'E-Mail'}
                            value={email}
                            disabled={!!email}
                            required={true}
                        />
                        <Input
                            type={'password'}
                            placeholder={'Passwort'}
                            value={password}
                            onChange={(e) => handleChangePassword('password', e.currentTarget.value)}
                            onBlur={(e) => handleBlurPasswordField('password', e.currentTarget.value)}
                            validationMessage={passwordVal}
                            required={true}
                            showEye={true}
                        />
                        {errorMessage && <span>{errorMessage}</span>}
                        <button onClick={handleSetPassword}>Next</button>
                    </div>
                </div>
                <div>
                    <img src={Robbot} alt={Robbot}/>
                </div>
            </div>
        </PageComponent>

    )
}
export default SetPassword
