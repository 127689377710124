import React, {useEffect, useRef } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import FlexiiLogo from '../../../../icons/flexiiLogo.svg'
import Edit from "../../../../icons/edit.svg";
import TypingEffect from "../TypingEffect";
import {setCareReceipients} from "../../../../redux/actions/careReceipients";
import {useDispatch, useSelector} from "react-redux";
import {setCareQuizQuestions} from "../../../../redux/actions/quizQuestions";
import {uploadFile} from "../../../../api/formApi";
import { setConfirmationModal} from "../../../../redux/actions/confirmationModal";
import CareModalComponent from "../NestedComponentOfModal/CareModalComponent/CareModalComponent";

const CareChallenge = (
    {
        quizCare,
        notCareAnsweredIndex,
        editList,
        setEdit,
        getTextValue,
        handleCancel,
        handleCareTextAnswer,
        edit,
        handleEdit,
        careReceipients,
        indexCare,
        messageRefs
    }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const fileName = quizCare.find((item)=>item.type === 'file')?.fileName
    const {
        payment:{reimbursementRef},
        quizQuestions:{index,groupName, editSummary}
    } = useSelector((state) => state)

    useEffect(() => {
            quizCare.map((item,i)=>{
                if(!!item.edit && index === i){
                    ( item.type === 'date' ||  item.type === 'rate' ||  item.type === 'option' || item.type === 'submit') &&
                    handleModalCare(item,i)
                }
            })
    }, [groupName]);

    const handleFileChange = (e,index) => {
        const file = e.target.files[0]
        let formData = new FormData();
        formData.append('file', file);
        uploadFile(formData,reimbursementRef,'RC_CARE_RECIPIENT')
            .then((res)=> {
                const newQuiz= quizCare.map((item,i)=> {
                    if(index === i){
                        return {
                            ...item,
                            answers: res.amazonUrl,
                            fileName: file.name
                        }
                    }
                    return item
                })
                dispatch(setCareReceipients(Object.keys(careReceipients)[index],res.amazonUrl))
                dispatch(setCareQuizQuestions(newQuiz,indexCare))
            })
            .catch((res)=> console.log(res))
    };
    const handleDivClick = () => {
        // Simulate click on hidden input when div is clicked
        fileInputRef.current.click();
    };
    const downloadFile = (url, name) => {
        if(url === '-'){
            return null
        }else {
            fetch(url)
                .then((response) => (response.blob()))
                .then((blob) => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${name}`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link?.parentNode?.removeChild(link);
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const handleModalCare = (item,i) => {
        dispatch(
            setConfirmationModal({
                headerText: 'Edit',
                body: <CareModalComponent item={item} careIndex={i}/>,
                large:true
            })
        )
    }
    return(
        <>
            {quizCare?.slice(0, notCareAnsweredIndex + 1)?.map((item,i)=>{
                // console.log(item.questionKey);
                return (
                    <>
                        <li className={styles.odd} ref={el => (messageRefs.current['careReceipients' + i] = el)}>
                            <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
                            <p>
                                {
                                    item?.answers  ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                }
                            </p>
                        </li>
                        {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                            [styles.hideList]: editList !== 1,
                            [styles.borderLi] : !!item.edit && (item.type === 'text' || item.type === 'number' || item.type === 'phoneNumber')
                        })}
                                               onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                            {!!item.edit && (item.type==='text' || item.type==='number' || item.type==='phoneNumber') ? <>
                                            <textarea onChange={(e)=>getTextValue(e)} autoFocus={true} >
                                                {item?.answers}
                                            </textarea>
                                            <div>
                                                <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                                <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                    handleCareTextAnswer(i)
                                                }}/>
                                            </div>
                                             </>
                                            : <p>
                                    {item.type==='file'? item.fileName : item?.answers}
                                    {item?.type === 'file' && <img src={Download} alt="Download" onClick={()=>downloadFile(careReceipients.certificate,fileName)}/>}
                                                {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=> item.type === 'file'? handleDivClick() :
                                                   ( item.type === 'date' ||  item.type === 'rate' ||  item.type === 'option' || item.type === 'submit') ? handleModalCare(item,i) : handleEdit(i)}/>}
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={(e)=> handleFileChange(e,i)}
                                                    accept="image/jpeg,image/png,.pdf"
                                                />
                                            </p>
                                }
                            </li>
                            }
                        {item?.returnQuestion &&
                            <div className={styles.returnQuestion}>
                                <img src={item?.img} alt={item?.img}/>
                                <p>
                                    {item?.returnQuestion}
                                </p>
                           </div>
                        }
                    </>

                )

            })}
        </>
    )
}
export default CareChallenge
