import React, {useEffect} from "react";
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import Edit from "../../../../icons/edit.svg";
import TypingEffect from "../TypingEffect";
import {useDispatch, useSelector} from "react-redux";
import {setConfirmationModal} from "../../../../redux/actions/confirmationModal";
import AuthModalComponent from "../NestedComponentOfModal/AuthModalComponent";
// import TypingEffect from "../TypingEffect";

const AuthChallenge = (
    {
        quizAuth,
        notAuthAnsweredIndex,
        editList,
        setEdit,
        getTextValue,
        handleAuthTextAnswer,
        edit,
        handleEdit,
        handleCancel,
        messageRefs
    }
) => {
    const {
        quizQuestions:{editSummary,index,groupName}
    } = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        quizAuth.map((item,i)=>{
            if(!!item.edit && index === i){
                console.log(item);
                ( item.type === 'birthDay' || item.type === 'date'  ||  item.type === 'option' || item.type === 'submit') &&
                handleModalAuth(item,i)
            }
        })
    }, [groupName]);
    const handleModalAuth = (item,i) => {
        dispatch(
            setConfirmationModal({
                headerText: 'Edit',
                body: <AuthModalComponent item={item} authIndex={i}/>,
                large:true
            })
        )
    }
    return(
        <>
            {
                quizAuth?.slice(0, notAuthAnsweredIndex + 1).map((item,i)=>{
                    return (
                        <>
                            <li className={styles.odd} ref={el => (messageRefs.current['authorizedPerson' + i] = el)}>
                                <img src={item?.img} alt={item?.img}/>
                                <p>
                                    {
                                        item.answers ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                    }
                                </p>
                            </li>
                            {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                                [styles.hideList]: editList !== 1,
                                [styles.borderLi] : !!item.edit && (item.type==='text' || item.type==='number' || item.type==='phoneNumber')
                            })}
                                                   onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                                {!!item.edit  && (item.type==='text' || item.type==='number' || item.type==='phoneNumber') ? <>
                                            <textarea  onChange={(e)=>getTextValue(e)} autoFocus={true}>
                                                {item?.answers}
                                            </textarea>
                                        <div>
                                            <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                            <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                handleAuthTextAnswer(i)
                                            }}/>
                                        </div>
                                    </>
                                    : <p>
                                        {item.answers}
                                        {item?.type === 'file' && <img src={Download} alt={Download}/>}
                                        {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=> (item.type !=='text' ||
                                            item.type !=='number'  || item.type !=='phoneNumber') ? handleModalAuth(item, i) : handleEdit(i)}/>}
                                    </p>
                                }
                            </li> }
                            {item?.returnQuestion &&
                                <div className={styles.returnQuestion}>
                                    <img src={item?.img} alt={item?.img}/>
                                    <p>
                                        {item?.returnQuestion}
                                    </p>
                                </div>
                            }
                        </>)
                })
            }
        </>
    )
}
export default AuthChallenge
