import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation} from 'react-router-dom'
import { completePaymentMethod } from '../../../../api/paymentApi';
import {  submitReimbursement } from '../../../../api/formApi';
import SetPassword from "../../Option 2/SetPassword";
import { getReimbursements } from '../../../../api/userApi';
import { setUserEmail } from '../../../../redux/actions/authActions';
import { useDispatch } from 'react-redux';
import { setReimbursementRef } from '../../../../redux/actions/payment';

const PaymentResult = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search);
    const refFromParams = queryParams.get('ref');
    const [ status, setStatus ] = useState(null);
    const reimbursementRef = refFromParams
    const navigate = useNavigate()
    useEffect(() => {
        completePaymentMethod({ reimbursementRef })
            .then(() => {
                getReimbursements(reimbursementRef)
                    .then((data) =>{
                        setStatus('OK')
                        dispatch(setUserEmail(data.email))
                        dispatch(setReimbursementRef(data.ref))
                        const requestBody = {
                            ref: data.ref,
                            email: data.email,
                            option: 2,
                        }
                        submitReimbursement(requestBody)
                            .then(() => {})
                            .catch((err) => console.log(err))
                    })


            }).catch(() => navigate('/payment'))
    }, [])

    return (
        status && <SetPassword/>
    )
}


export default PaymentResult
