import React, {useEffect} from "react";
import styles from "../ChallengeSteps/challengeSteps.module.scss";
import cx from "classnames";
import CancelBtn from "../../../../icons/cancelBtn.svg";
import AcceptBtn from "../../../../icons/acceptBtn.svg";
import Download from "../../../../icons/download.svg";
import Edit from "../../../../icons/edit.svg";
import { setIndividual} from "../../../../redux/actions/careReceipients";
import {useDispatch, useSelector} from "react-redux";
import CheckboxChecked from '../../../../icons/checkbox-checked.svg'
import CheckboxUnchecked from '../../../../icons/checkbxo-unchecked.svg'
import TypingEffect from "../TypingEffect";
import {setConfirmationModal} from "../../../../redux/actions/confirmationModal";
import ActiveModalComponent from "../NestedComponentOfModal/ActiveModalComponent/ActiveModalComponent";
import IndividualModalComponent from "../NestedComponentOfModal/IndividualModalComponent";
import Euro from "../../../../icons/Euro.svg";
import KM from "../../../../icons/km.svg";
// import TypingEffect from "../TypingEffect";
const PrivateIndividualChallenge = ({
    sumPrivate,
    quizPrivate,
    notAPrivateAnsweredIndex,
    editList,
    setEdit,
    getTextValue,
    handlePrivateTextAnswer,
    edit,
    handleEdit,
    handleCancel,
    messageRefs
}) => {
    const {
        representationByPrivateIndividual,
        quizQuestions:{editSummary,groupName,index}
    } = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        quizPrivate.map((item,i)=>{
            if(!!item.edit && index === i){
                console.log(item);
                ( item.type === 'date' ||  item.type === 'submit') &&
                handleIndividualModal(item,i)
            }
        })
    }, [groupName]);

    const handleIAgree = (i) => {
        if(representationByPrivateIndividual.iAgree === ''){
            dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[i],'checked'))
        }else {
            dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[i],''))
        }
    }
    const handleIHave = (i) => {
        if(representationByPrivateIndividual.iHave === ''){
            dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[i],'checked'))
        }else {
            dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[i],''))
        }

    }
    const handleIndividualModal = (item,i) => {
        dispatch(
            setConfirmationModal({
                headerText: 'Edit',
                body: <IndividualModalComponent item={item} individualIndex={i}/>,
                large:true
            })
        )
    }
    return (
        <>
            {
                quizPrivate.slice(0, notAPrivateAnsweredIndex + 1).map((item,i)=>{
                    return (
                        <>
                            <li className={cx(styles.odd)} ref={el => (messageRefs.current['representationByPrivateIndividual' + i] = el)}>
                                {item.type === 'iAgree' ?  <p className={styles.checkbox}>
                                    <img
                                        className={styles.checkboxStyles}
                                        src={
                                            representationByPrivateIndividual.iAgree
                                            ? CheckboxChecked
                                            : CheckboxUnchecked
                                        }
                                        alt={'Select'}
                                        onClick={()=>handleIAgree(i)}
                                    />
                                    {
                                        item.answers || representationByPrivateIndividual.iAgree ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                    }
                                </p> : item.type === 'iHave' ? representationByPrivateIndividual.iAgree && <p className={styles.checkbox}>
                                    <img
                                        className={styles.checkboxStyles}
                                        src={
                                            representationByPrivateIndividual.iHave
                                                ? CheckboxChecked
                                                : CheckboxUnchecked
                                        }
                                        alt={'Select'}
                                        onClick={()=>handleIHave(i)}
                                    />
                                        {
                                            item.answers || representationByPrivateIndividual.iHave  ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                        }
                                  </p>
                                 :  <>
                                        <img src={item?.img} alt={item?.img}/>
                                        <p>
                                            {
                                                item.answers ? item.question : <TypingEffect text={item?.question} speed={50}/>
                                            }
                                        </p>
                                    </>
                                }
                            </li>

                            {item?.answers  && <li className={cx(styles.even,{[styles.fileStyle]: item?.type === 'file',
                                [styles.hideList]: editList !== 1,
                                [styles.borderLi] : !!item.edit && (item.type==='time' || item.type==='text' || item.type==='integer')
                            })}
                                                   onMouseOver={()=>setEdit(i)} onMouseOut={()=>setEdit(-1)}>
                                {!!item.edit && (item.type==='time' || item.type==='text' || item.type==='integer') ? <>
                                            <textarea onChange={(e)=>getTextValue(e)} autoFocus={true}>
                                                {item?.answers}
                                            </textarea>
                                        <div>
                                            <img src={CancelBtn} alt={CancelBtn} onClick={()=>handleCancel(i)}/>
                                            <img src={AcceptBtn} alt={AcceptBtn} onClick={()=> {
                                                handlePrivateTextAnswer(i)
                                            }}/>
                                        </div>
                                    </>
                                    : <p>
                                        {/*{item.question === 'Total amount' && isNaN(sumPrivate) ? '-' : item.question === 'Total amount' && !isNaN(sumPrivate) ? sumPrivate : item.answers}*/}
                                        {item.answers}
                                        {(item.question === 'Respite care 1: Remuneration' || item.question === 'Respite care 2: Remuneration' || item.type === 'sum')
                                            && <img src={Euro} alt={Euro} className={styles.euro}/>}
                                        {item?.type === 'file' && <img src={Download} alt={Download}/>}
                                        {edit === i && !editSummary && <img src={Edit} alt={Edit} onClick={()=>
                                            item.type === 'date' || item.type === 'submit' ? handleIndividualModal(item,i) :handleEdit(i)}/>}
                                    </p>
                                }
                            </li> }
                        </>)
                })
            }
        </>
    )
}
export default PrivateIndividualChallenge
